import {React,Component} from "react";
import { Container } from "react-bootstrap";

import '../styles/addevent.css'

//import database
import db from './firestore';

class Create extends Component {
    constructor() {
        super();
        this.state = {
            title: '',
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            color:'',
            delete_title: '',
        };
    }

    updateInput = e => {
        this.setState({
          [e.target.name]: e.target.value
        });
    };
    myTopicHandler = event => {
        this.setState({
          color: event.target.value
        });
      };

    addEvent = e => {
        e.preventDefault();
        const userRef = db.collection('events').doc().set({
            array: {id: this.state.title, color: this.state.color, title: this.state.title, start_date: new Date(this.state.start_date + ' ' + this.state.start_time), end_date: new Date(this.state.end_date+ ' ' + this.state.end_time)}
        });  
        this.setState({
            title: '',
            start_date: '',
            end_date: '',
        });
        alert("Event added successfully")
    };

    deleteEvent = e => {
        e.preventDefault();
        const val = this.state.delete_title
        db.collection("events").get().then((querySnapshot) => {
                querySnapshot.forEach(element => {
                    var item = element.data().array;
                    if(item.title == val){
                        db.collection('events').doc(element.id).delete()
                    }
                });
          })

        this.setState({
            delete_title: '',
        });
        alert("Event " + this.state.delete_title + " Removed successfully")
    };

    logoutHandler = e => {
        sessionStorage.removeItem('isAuth');
        sessionStorage.clear();
        window.location.reload();
    };


    render() {
        return (
            <Container className="newpost">
                <h2>Add Event</h2>
                <br></br>
                <form className="pbox">
                <label>Title</label>
                <input className="inp" name ='title' placeholder="Enter Title" wrap="hard" onChange={this.updateInput} value={this.state.title} required />
                <label>Start Date</label>
                <input className="inp" name ='start_date' placeholder="mm/dd/yyyy" wrap="hard" onChange={this.updateInput} value={this.state.start_date} required />
                <label>Start Time</label>
                <input className="inp" name ='start_time' placeholder="xx:xx:xx (24hr/military time)" wrap="hard" onChange={this.updateInput} value={this.state.start_time} required />
                <label>End Date</label>
                <input className="inp" name ='end_date' placeholder="mm/dd/yyyy" wrap="hard" onChange={this.updateInput} value={this.state.end_date} required />
                <label>End Time</label>
                <input className="inp" name ='end_time' placeholder="xx:xx:xx (24hr/military time)" wrap="hard" onChange={this.updateInput} value={this.state.end_time} required />
                <label>Color</label>
                <select id="colors" onChange={this.myTopicHandler}>
                    <option>blue</option>
                    <option>red</option>
                    <option>green</option>
                </select>
                <button type='submit' onClick={this.addEvent} className="submitbutto">Submit</button>
                </form>

                <br></br>

                <h2>Delete Event</h2>
                <br></br>
                <form className="pbox">
                    <label>Title</label>
                    <input className="inp" name ='delete_title' placeholder="Title to be Deleted" wrap="hard" onChange={this.updateInput} value={this.state.delete_title} required />
                    <button type='submit' onClick={this.deleteEvent} className="submitbutto">Submit</button>
                </form>

                <br></br>
                <button onClick={this.logoutHandler}>logout</button>
            </Container>
        )
    }
}

export default Create;




/*

array: {title: this.state.title, start_date: new Date(this.state.start_date + ' ' + this.state.start_time), end_date: new Date(this.state.end_date+ ' ' + this.state.end_time)}



<form className="pbox">
<label>Title</label>
<input className="inp" name ='title' placeholder="Enter Title" wrap="hard" onChange={this.updateInput} value={this.state.title} required />
<label>Start Date</label>
<input className="inp" name ='start_date' placeholder="mm/dd/yyyy" wrap="hard" onChange={this.updateInput} value={this.state.start_date} required />
<label>Start Time</label>
<input className="inp" name ='start_time' placeholder="xx:xx:xx  Please add using military time" wrap="hard" onChange={this.updateInput} value={this.state.start_time} required />
<label>End Date</label>
<input className="inp" name ='end_date' placeholder="mm/dd/yyyy" wrap="hard" onChange={this.updateInput} value={this.state.end_date} required />
<label>End Time</label>
<input className="inp" name ='end_time' placeholder="xx:xx:xx  Please add using military time" wrap="hard" onChange={this.updateInput} value={this.state.end_time} required />

<button type='submit' onClick={this.addEvent} className="submitbutto">Submit</button>
</form>

*/