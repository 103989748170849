import React, {useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Popup from 'react-popup';

//Stylesheets
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../styles/calendar.css'

// Initialize Firebase
import db from './firestore';
import firebase from 'firebase/compat/app';
import firestore from 'firebase/compat'

//used to get localized calendar date time etc using moment
const localizer = momentLocalizer(moment);

const tempevents = [
  {id: '4', title:'test', start_date:new Date('10/12/2021 08:20:00'), end_date:new Date('10/13/2021 08:20:00'), color: ''},
  {id: '5',title:'test', start_date:new Date('10/12/2021 08:20:00'), end_date:new Date('10/13/2021 08:20:00'), color: 'orange'},
  {id: '6',title:'test', start_date:new Date('10/12/2021 08:20:00'), end_date:new Date('10/13/2021 08:20:00'), color: 'green'},
]

function ViewCal() {
  const [events,setEvent]=useState([]);
  
  const getEvents = ()=>{
    db.collection("events").get().then((querySnapshot) => {
      //loop through docs
        querySnapshot.forEach(element => {
            var item = element.data().array;
            //must call toDate on firebases timestamp 
            setEvent(arr => [...arr , {id: item.title, title: item.title, start_date: item.start_date.toDate(), end_date: item.end_date.toDate(), color: item.color}]);
        });
        //logging events to check data
        //console.log(events)
    })
  }
  
  
  window.addEventListener('load', () => {
    getEvents();
  });

  return (
    <div className='calendar-page'>

        {/* Container to hold the calendar */}
        <Container className='calendar-container'>
            <Calendar className='calendar' popup localizer={localizer} events={events} startAccessor="start_date" endAccessor="end_date" views={['month','week']}
            eventPropGetter={event => {const eventData = events.find(ot => ot.id === event.id);const backgroundColor = eventData && eventData.color;return { style: { backgroundColor } };}}
            onSelectEvent={event => alert(event.title +' is occuring from '+ event.start_date +' to '+ event.end_date)}
            />
        </Container>

    </div>
  );
}

export default ViewCal;






